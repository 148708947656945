<template>
  <tr>
    <th
      v-for="data in tableData"
      :key="data.index"
      class="table-header"
      :style="{
         letterSpacing: tenantStyleGuide.global.letterSpacing,
         fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
         color: tenantStyleGuide.content.fontPrimary,
       }"
    >
      {{ data }}
    </th>
  </tr>
</template>

<script>
import DefaultTheme from '../../../theme/default.json'
import DefaultMessages from '../../../messages/default.json'

export default {
  name: 'TableHeader',
  props: {
    tableData: {
      type: Array,
      default: () => ([]),
      require: false
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  data () {
    return {
      tenantMessages: {}
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  }
}
</script>

<style lang="sass" scoped>
@import "../../../assets/_fonts"

.table-header
  padding: 16px 10px
  text-align: left
</style>
