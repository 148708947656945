/**
 * @param {String} value
 * @returns {String}
 */
export default function convertBase64 (value) {
  if (Buffer.from(value, 'base64').toString('base64') === value) {
    return Buffer.from(value, 'base64')
  } else {
    return '<svg viewBox="0 0 20 20"><ellipse id="path10" cx="10" cy="10" rx="10" ry="10" /></g></svg>'
  }
}
