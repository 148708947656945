/**
 * @param {Number} value
 * @returns {String}
 */

export function formatInReais (value) {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL'
    }).format(value || 0)
  } else {
    return new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL'
    }).format(0)
  }
}

export function formatInDollar (value) {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('eng', {
      style: 'currency',
      currency: 'USD'
    }).format(value || 0)
  } else {
    return new Intl.NumberFormat('eng', {
      style: 'currency',
      currency: 'USD'
    }).format(0)
  }
}

export default {
  formatInReais,
  formatInDollar
}
