<template>
<tr>
  <td
    v-for="data in tableDataColumn"
    :key="data.index"
    class="table-data"
    :style="{
      borderTop: tenantStyleGuide.content.boxBorder,
      letterSpacing: tenantStyleGuide.global.letterSpacing,
      fontFamily: tenantStyleGuide.content.fontFamilySecondary,
      color: tenantStyleGuide.content.fontSecondary,
      letterSpacing: tenantStyleGuide.global.letterSpacing
    }"
  >

    <Tag v-if="data.type === 'tag'"  :text="data.value"  :tenant-style-guide="tenantStyleGuide" />

    <span v-if="data.type === 'text'">
      {{ data.value }}
    </span>

    <Status
      v-if="data.type === 'status'"
      :tenant-style-guide="tenantStyleGuide"
      :status="data.value"
    />

    <span v-if="data.type === 'currency'">
      {{  currencyFormatter(data.value) }}
    </span>

    <span v-if="data.type === 'date'">
      {{   dateFormatter(data.value) }}
    </span>

    <action
      v-if="data.type === 'action'"
      :path="data.value"
    >
      <template v-slot:icon>
         <div class="icon" v-html="svgIcon(tenantStyleGuide.icons.details)" :style="{ fill: tenantStyleGuide.content.fontPrimary }" />
      </template>
    </action>
  </td>
</tr>
</template>

<script>
import convertBase64 from '../../../utils/base64'
import currencyFormatter from '../../../utils/formatCurrency'
import dateFormatter from '../../../utils/formatDate'

import DefaultTheme from '../../../theme/default.json'
import DefaultMessages from '../../../messages/default.json'

import Action from '../../Action'
import Status from '../../Status'
import Tag from '../../Tag'

export default {
  name: 'TableRow',
  props: {
    tableDataColumn: {
      type: Array,
      default: () => ([{ type: '', value: '' }]),
      require: false
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  components: {
    Action,
    Status,
    Tag
  },
  data () {
    return {
      tenantMessages: {}
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  },
  methods: {
    svgIcon (value) {
      return convertBase64(value)
    },
    currencyFormatter (value) {
      return currencyFormatter.formatInReais(value / 100)
    },
    dateFormatter (value) {
      return dateFormatter.brazilianFormat(value)
    }
  }

}
</script>

<style lang="sass" scoped>
@import "../../../assets/_fonts"

.table-data
  padding: 16px 10px
  text-align: left
  font-weight: normal

.icon
  width: 5px

  & svg path
    fill: currentcolor
</style>
