<template>
  <div
    class="status"
    :style="{
        fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
        color: tenantStyleGuide.content.fontPrimaryLight,
        background: statusBackgroundColor,
        border: statusBorderStyle,
        borderRadius:  tenantStyleGuide.status.borderRadius,
        textTransform: tenantStyleGuide.status.textTransform,
        fontSize: tenantStyleGuide.status.fontSize,
        letterSpacing: tenantStyleGuide.content.buttonLetterSpacing
      }"
  >
    {{  statusText }}
  </div>
</template>

<script>
import DefaultTheme from '../../theme/default.json'
import DefaultMessages from '../../messages/default.json'

export default {
  name: 'Status',
  props: {
    status: {
      type: String,
      require: true,
      validator (value) {
        return ['fulfilled', 'canceled', 'pending', 'denied', 'expired', 'noExpire', 'toExpire', 'credit', 'debit'].includes(value)
      }
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  },
  computed: {
    statusBackgroundColor () {
      let statusBackground = this.tenantStyleGuide.status.confirmed

      const statusBackgrounds = {
        fulfilled: this.tenantStyleGuide.status.confirmed,
        canceled: this.tenantStyleGuide.status.canceled,
        pending: this.tenantStyleGuide.status.pending,
        denied: this.tenantStyleGuide.status.denied,
        expired: this.tenantStyleGuide.status.expired,
        toExpire: this.tenantStyleGuide.status.toExpire,
        noExpire: this.tenantStyleGuide.status.noExpire,
        credit: this.tenantStyleGuide.status.credit,
        debit: this.tenantStyleGuide.status.debit
      }

      statusBackground = statusBackgrounds[this.status]

      return statusBackground
    },
    statusBorderStyle () {
      let statusBorderStyle = this.tenantStyleGuide.status.confirmedBorder

      const statusBorder = {
        fulfilled: this.tenantStyleGuide.status.confirmedBorder,
        canceled: this.tenantStyleGuide.status.canceledBorder,
        pending: this.tenantStyleGuide.status.pendingBorder,
        denied: this.tenantStyleGuide.status.deniedBorder,
        expired: this.tenantStyleGuide.status.expiredBorder,
        toExpire: this.tenantStyleGuide.status.toExpireBorder,
        noExpire: this.tenantStyleGuide.status.noExpireBorder,
        credit: this.tenantStyleGuide.status.creditBorder,
        debit: this.tenantStyleGuide.status.debitBorder
      }

      statusBorderStyle = statusBorder[this.status]

      return statusBorderStyle
    },
    statusText () {
      let statusMessage = this.tenantMessages.status.fulfilled

      const statusMessages = {
        fulfilled: this.tenantMessages.status.fulfilled,
        canceled: this.tenantMessages.status.canceled,
        pending: this.tenantMessages.status.pending,
        denied: this.tenantMessages.status.denied,
        expired: this.tenantMessages.status.expired,
        toExpire: this.tenantMessages.status.toExpire,
        noExpire: this.tenantMessages.status.noExpire,
        credit: this.tenantMessages.status.credit,
        debit: this.tenantMessages.status.debit
      }

      statusMessage = statusMessages[this.status]

      return statusMessage
    }
  }

}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"
@import "../../assets/_fonts"

.status
  text-align: center
  max-width: 200px
  padding: 5px
</style>
