<template>
  <router-link :to="path">
    <slot class="icon" name="icon" />
  </router-link>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: '#',
      require: false
    }
  }
}
</script>
