<template>
  <table class="table" :style="{ background: tenantStyleGuide.content.boxBackground, border: tenantStyleGuide.content.boxBorder, borderRadius: tenantStyleGuide.content.boxBorderRadius}">
    <thead>
      <slot name="header" />
    </thead>
    <tbody>
      <slot name="row">
        <tr>
          <th
            align="center"
            colspan="100"
            :style="{
              letterSpacing: tenantStyleGuide.global.letterSpacing,
              fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
              color: tenantStyleGuide.content.fontSecondary,
            }"
          >
            No Data
          </th>
        </tr>
      </slot>
    </tbody>
  </table>
</template>

<script>
import DefaultTheme from '../../../theme/default.json'
import DefaultMessages from '../../../messages/default.json'

export default {
  name: 'TableBody',
  props: {
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  data () {
    return {
      tenantMessages: {}
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  }
}
</script>

<style lang="sass" scoped>
@import "../../../assets/_mixins"
@import "../../../assets/_fonts"

.table
  width: 100%
  padding: 16px
  overflow-x: auto
  white-space: nowrap
  @include mobile-and-down
    display: block
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none
  scrollbar-width: none
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar
    display: none

</style>
