<template>
  <div class="types">
    <div class="types__icon"
      :style="{
        color: tenantStyleGuide.content.primaryColor,
        background: typesBackgroundColor,
        border: tenantStyleGuide.tags.border,
      }"
    >
      <div class="types__icon-color">
        <div  v-if="text !== 'expiration' && text !== 'chargeback'" v-html="svgIcon(tenantStyleGuide.icons.cashbackIn)" :style="{ fill: tenantStyleGuide.content.fontPrimary}" />
        <div  v-else v-html="svgIcon(tenantStyleGuide.icons.cashbackOut)" :style="{ fill: tenantStyleGuide.content.fontPrimary }" />
      </div>
    </div>
    <div
      class="types__text"
      :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary }"
    >
      {{ typesText }}
    </div>
  </div>
</template>

<script>
import convertBase64 from '../../utils/base64'

import DefaultTheme from '../../theme/default.json'
import DefaultMessages from '../../messages/default.json'

export default {
  name: 'Tag',
  props: {
    text: {
      type: String,
      require: true,
      validator (value) {
        return ['purchase', 'chargeback', 'expiration', 'incentive'].includes(value)
      }
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  },
  computed: {
    typesBackgroundColor () {
      let typesBackground = this.tenantStyleGuide.tags.purchase
      if (this.text === 'purchase') {
        typesBackground = this.tenantStyleGuide.tags.purchase
      }

      if (this.text === 'chargeback') {
        typesBackground = this.tenantStyleGuide.tags.chargeback
      }

      if (this.text === 'expiration') {
        typesBackground = this.tenantStyleGuide.tags.expiration
      }

      if (this.text === 'incentive') {
        typesBackground = this.tenantStyleGuide.tags.incentive
      }

      return typesBackground
    },
    typesText () {
      let typesMessage = this.tenantMessages.types.purchase
      if (this.text === 'purchase') {
        typesMessage = this.tenantMessages.types.purchase
      }

      if (this.text === 'chargeback') {
        typesMessage = this.tenantMessages.types.chargeback
      }

      if (this.text === 'expiration') {
        typesMessage = this.tenantMessages.types.expiration
      }

      if (this.text === 'incentive') {
        typesMessage = this.tenantMessages.types.incentive
      }

      return typesMessage
    }
  },
  methods: {
    svgIcon (value) {
      return convertBase64(value)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"
@import "../../assets/_fonts"

.types
  @include display-row
  align-items: center

  &__icon
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    margin-right: 10px
    align-items: center
    border-radius: 100%

    &-color
      width: 20px
      height: 20px

      & svg path
        fill: currentcolor
  &__text
    font-size: 16px
</style>
