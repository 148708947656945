import request from '../utils/request'

export function getWalletTheme (apiKey) {
  return request({
    url: '/tenant-settings/get-my-wallet-theme',
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}

export default {
  getWalletTheme
}
