import { format } from 'date-fns'

/**
 * @param {String} value
 * @returns {String} // 2022-02-14T19:51:37.521Z
 */

export function brazilianFormat (value) {
  let date = new Date(value)
  if (isNaN(date) || value === null) {
    date = 'Sem Data'
    return date
  } else {
    const formattedDate = format(date, 'dd/MM/yyyy HH:mm')

    return formattedDate
  }
}

export function usaFormat (value) {
  let date = new Date(value)
  if (isNaN(date) || value === null) {
    date = 'Without Date'
    return date
  } else {
    const formattedDate = format(date, 'MM/dd/yyyy HH:mm')

    return formattedDate
  }
}

export default {
  brazilianFormat,
  usaFormat
}
